import styled, { css } from 'styled-components';

import { Label } from '../../../../../components/Toolkit/Label/Label';

import { StyleProps } from './Navigation.types';
import { ActiveLink, ActiveButton } from '../../../ActiveLink/ActiveLink';

export const StyledNavigationList = styled.ul`
  list-style-type: none;
  display: flex;

  ${({ theme }) =>
    theme.headerTheme?.inModal &&
    `
    flex-direction: column;
  `}
  @media only screen and (min-width: ${({ theme }) =>
    theme.headerTheme?.pageBreakpoint}) {
    align-items: center;
  }
`;

export const StyledActiveLink = styled(ActiveLink)`
  display: block;
  padding-left: ${({ theme }) => theme.spacing.L48};
  position: relative;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &.active {
    color: ${({ theme }) => theme.color.PRIMARY};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    padding: 0 ${({ theme }) => theme.spacing.M24};
  }
`;

export const NavSubLinkCSS = css`
  display: block;
  line-height: 34px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    line-height: ${({ theme }) => theme.spacing.L40};
  }

  :hover {
    background: ${({ theme }) => theme.color.GREY_LIGHTEST};
  }
  &.active {
    color: ${({ theme }) => theme.color.PRIMARY};
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    margin-left: 0;
    display: flex;
    align-content: center;
  }
`;

export const NavSubLinkListItem = styled.li<{
  hideLinkOnMobile?: boolean;
  hideLinkOnDesktop?: boolean;
}>`
  ${NavSubLinkCSS}
  ${({ hideLinkOnMobile }) => hideLinkOnMobile && 'display: none;'}
  
    @media only screen and (min-width: ${({ theme }) =>
    theme.headerTheme?.pageBreakpoint}) {
    ${({ hideLinkOnDesktop }) => hideLinkOnDesktop && 'display: none;'}
  }
`;

export const NavSubLinkList = styled(({ rightAlign, ...rest }) => (
  <ul {...rest} />
))`
  margin: 0px 0 0px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    postiion: relative;
    border: 1px solid ${({ theme }) => theme.color.GREY_LIGHTER};
    border-radius: ${({ theme }) => theme.border.radius.STANDARD};
    background-color: ${({ theme }) => theme.color.WHITE};
    box-shadow: inset 0 0 16px 0 rgba(0, 0, 0, 0.02);
    padding: 17px 0px 17px;
    line-height: 17px;
    width: 259px;
    margin: 12px 0 0 -26px;
    ${(props: StyleProps) =>
      props.rightAlign &&
      `
      position: relative;
      left: -166px;
    `};
  }
`;

export const TopLevelItemCSS = css`
  background: transparent;
  display: inline;
  color: ${({ theme }) => theme.headerTheme?.navLinkColor};
  cursor: pointer;
  border: 0;
  padding: 0;
  position: relative;
  margin: 0 33px 14px;

  ${(props: StyleProps) => props.rightAlign && `margin-right: 0;`};
  ${({ theme }) =>
    theme.headerTheme?.inModal &&
    `
      flex-direction: column;
      margin: 10px 33px 16px;
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.headerTheme?.pageBreakpoint}) {
    font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
    width: auto;
    margin: 0 ${({ theme }) => theme.spacing.M24} 0 0;
    text-align: ${(props: StyleProps) =>
      props.rightAlign ? 'right' : 'inherit'};
    &.active,
    :hover,
    :focus {
      outline: 0;
      border: none;
      color: ${({ theme }) => theme.headerTheme?.activeNavLinkColor};
      &:after {
        content: '';
        background-color: ${({ theme }) =>
          theme.headerTheme?.activeNavLinkColor};
        position: absolute;
        left: 0px;
        display: block;
        height: 3px;
        width: 100%;
        bottom: -9px;
      }
    }
  }
`;

export const TopLevelItemActiveFakeButton = styled(
  ({ rightAlign, ...rest }) => <ActiveLink {...rest} />,
)`
  ${TopLevelItemCSS};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

  ${({ theme }) =>
    theme.headerTheme?.inModal &&
    `
        pointer-events: none;
        margin-bottom: 16px;
      `}
`;

export const TopLevelItemActiveButton = styled(({ rightAlign, ...rest }) => (
  <ActiveButton {...rest} />
))`
  ${TopLevelItemCSS};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};

  ${({ theme }) =>
    theme.headerTheme?.inModal &&
    `
        pointer-events: none;
      `}
`;

export const TopLevelItemActiveLink = styled(({ rightAlign, ...rest }) => (
  <ActiveLink {...rest} />
))`
  :visited {
    color: ${({ theme }) => theme.headerTheme?.navLinkColor};
  }

  ${TopLevelItemCSS}
  ${({ theme }) =>
    theme.headerTheme?.inModal &&
    `
        ${NavSubLinkCSS};
        margin: 0 0 14px 0;
        padding-left: 34px;
        font-weight: 400;
      `}
`;

export const StyledLI = styled.li<{ display: string }>`
  display: ${({ display }) => display};
  line-height: 20px;
  font-size: ${({ theme }) => theme.headerTheme?.fontSize};
  @media only screen and (min-width: ${({ theme }) =>
      theme.headerTheme?.pageBreakpoint}) {
    font-size: ${({ theme }) => theme.headerTheme?.desktopFontSize};
    cursor: pointer;
    display: inline-block;

    :hover ${TopLevelItemActiveFakeButton}, :hover ${TopLevelItemActiveButton} {
      color: ${({ theme }) => theme.headerTheme?.activeNavLinkColor};
      &:after {
        content: '';
        background-color: ${({ theme }) =>
          theme.headerTheme?.activeNavLinkColor};
        position: absolute;
        left: 0px;
        display: block;
        height: 3px;
        width: 100%;
        bottom: -9px;
      }
    }
    :last-of-type
      ${TopLevelItemActiveButton},
      :last-of-type
      ${TopLevelItemActiveLink} {
      margin: 0;
    }
  }
`;

export const LabelNewContainer = styled(Label)`
  ${({ theme }) =>
    theme.headerTheme?.inModal
      ? 'margin-left:12px;'
      : `
      position: absolute; 
      top: -18px; 
      right: -18px; 
    `}
`;

export const LabelNewSubItem = styled(Label)`
  ${({ theme }) =>
    theme.headerTheme?.inModal
      ? `
      position: absolute; 
      top: 8px; 
      right: 4px; 
    `
      : `
      position: absolute; 
      top: 13px; 
      right: 32px; 
    `}
`;
