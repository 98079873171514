import SearchSEOParams from '../services/Url/SearchSEOParams';
import {
  HOMEPAGE_URLS,
  LISTING_PAGE_BASE_URLS,
  PAGES,
  REALM_URLS,
  SEARCH_URLS,
  VT_SEARCH_URLS,
  VT_SEARCH_URLS_V2,
} from '../types';

const routes = require('@yolkai/next-routes').default;

const paths = SearchSEOParams.pathStrings;

export default routes()
  .add({
    name: 'homepage',
    pattern: `/${HOMEPAGE_URLS.BUY}`,
    page: '/index',
  })
  .add({
    name: 'rent-page',
    pattern: `/${HOMEPAGE_URLS.RENT}`,
    page: '/index',
  })
  .add({
    name: 'share-page',
    pattern: `/${HOMEPAGE_URLS.SHARE}`,
    page: '/index',
  })
  .add({
    name: 'newhomes-page',
    pattern: `/${HOMEPAGE_URLS.NEW_HOMES}`,
    page: '/index',
  })
  .add({
    name: 'sold-page',
    pattern: `/${HOMEPAGE_URLS.SOLD}`,
    page: '/index',
  })
  .add({
    name: 'student-accommodation-homepage',
    pattern: '/student-accommodation',
    page: '/student-accommodation',
  })
  .add({
    name: SEARCH_URLS.BUY,
    pattern: `/${SEARCH_URLS.BUY}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.RENT,
    pattern: `/${SEARCH_URLS.RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.SHARE,
    pattern: `/${SEARCH_URLS.SHARE}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({

    name: SEARCH_URLS.SOLD,
    pattern: `/${SEARCH_URLS.SOLD}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.RECENT,
    pattern: `/${SEARCH_URLS.RECENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.NEW_HOMES,
    pattern: `/${SEARCH_URLS.NEW_HOMES}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.COMMERCIAL_BUY,
    pattern: `/${SEARCH_URLS.COMMERCIAL_BUY}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.COMMERCIAL_RENT,
    pattern: `/${SEARCH_URLS.COMMERCIAL_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.STUDENT_ACCOMMODATION_RENT,
    pattern: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE,
    pattern: `/${SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.PARKING_BUY,
    pattern: `/${SEARCH_URLS.PARKING_BUY}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.PARKING_RENT,
    pattern: `/${SEARCH_URLS.PARKING_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.HOLIDAY_HOMES,
    pattern: `/${SEARCH_URLS.HOLIDAY_HOMES}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.INTERNATIONAL_BUY,
    pattern: `/${SEARCH_URLS.INTERNATIONAL_BUY}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: SEARCH_URLS.INTERNATIONAL_RENT,
    pattern: `/${SEARCH_URLS.INTERNATIONAL_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: PAGES.SEARCH,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.BUY,
    pattern: `/${LISTING_PAGE_BASE_URLS.BUY}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.RENT,
    pattern: `/${LISTING_PAGE_BASE_URLS.RENT}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.SHARE,
    pattern: `/${LISTING_PAGE_BASE_URLS.SHARE}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.SOLD,
    pattern: `/${LISTING_PAGE_BASE_URLS.SOLD}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.NEW_HOMES,
    pattern: `/${LISTING_PAGE_BASE_URLS.NEW_HOMES}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.COMMERCIAL_BUY,
    pattern: `/${LISTING_PAGE_BASE_URLS.COMMERCIAL_BUY}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.COMMERCIAL_RENT,
    pattern: `/${LISTING_PAGE_BASE_URLS.COMMERCIAL_RENT}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.PARKING_BUY,
    pattern: `/${LISTING_PAGE_BASE_URLS.PARKING_BUY}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.PARKING_RENT,
    pattern: `/${LISTING_PAGE_BASE_URLS.PARKING_RENT}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.HOLIDAY_HOMES,
    pattern: `/${LISTING_PAGE_BASE_URLS.HOLIDAY_HOMES}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.INTERNATIONAL_BUY,
    pattern: `/${LISTING_PAGE_BASE_URLS.INTERNATIONAL_BUY}/:address/:id`,
    page: PAGES.LISTING,
  })
  .add({
    name: LISTING_PAGE_BASE_URLS.INTERNATIONAL_RENT,
    pattern: `/${LISTING_PAGE_BASE_URLS.INTERNATIONAL_RENT}/:address/:id`,
    page: PAGES.LISTING,
  })
  // agents
  .add({
    name: VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER,
    pattern: `${REALM_URLS.AGENTS}/${VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTS}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS.RESIDENTIAL_RENT,
    pattern: `${REALM_URLS.AGENTS}/${VT_SEARCH_URLS.RESIDENTIAL_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTS}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS.RESIDENTIAL_SALES,
    pattern: `${REALM_URLS.AGENTS}/${VT_SEARCH_URLS.RESIDENTIAL_SALES}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTS}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS.COMMERCIAL,
    pattern: `${REALM_URLS.AGENTS}/${VT_SEARCH_URLS.COMMERCIAL}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTS}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: 'valuation-tool',
    pattern: `${REALM_URLS.AGENTS}/valuation-tool/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTS}/${PAGES.VT_SEARCH}`,
  })

  // agentsV2
  .add({
    name: VT_SEARCH_URLS_V2.PROPERTY_PRICE_REGISTER,
    pattern: `${REALM_URLS.AGENTSV2}/${VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTSV2}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS_V2.RESIDENTIAL_RENT,
    pattern: `${REALM_URLS.AGENTSV2}/${VT_SEARCH_URLS.RESIDENTIAL_RENT}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTSV2}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS_V2.RESIDENTIAL_SALES,
    pattern: `${REALM_URLS.AGENTSV2}/${VT_SEARCH_URLS.RESIDENTIAL_SALES}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTSV2}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: VT_SEARCH_URLS_V2.COMMERCIAL,
    pattern: `${REALM_URLS.AGENTSV2}/${VT_SEARCH_URLS.COMMERCIAL}/:${paths[0]}?/:${paths[1]}?`,
    page: `${REALM_URLS.AGENTSV2}/${PAGES.VT_SEARCH}`,
  })
  .add({
    name: 'valuation-tool-v2',
    page: `${REALM_URLS.AGENTSV2}/${PAGES.VT_SEARCH}`,
    pattern: `${REALM_URLS.AGENTSV2}/valuation-tool/:${paths[0]}?/:${paths[1]}?`,
  })
  .add({
    name: 'ppa-orders',
    pattern: `/ppa-orders`,
    page: `/ppa-orders`,
  })
  .add({
    name: 'sms-verification',
    pattern: `/sms-verification`,
    page: `/sms-verification`,
  });
